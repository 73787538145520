import React from "react";
import { Helmet } from "react-helmet";
const CerezPolitikasiPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-2 mx-10">
      <Helmet>
        <title>Ahde Zemin Sports - Çerez Politikası</title>
        <link
          rel="/"
          href="https://venerable-phoenix-f6f2be.netlify.app/cerez-politikasi"
        />
      </Helmet>
      <h1 className="text-3xl font-bold mb-4">Çerez Politikası</h1>
      <h1 className="text-xl font bold mb-4 mt-4">Çerez Nedir?</h1>
      <p>
        Çerezler web sitesi ziyareti sırasında ilgili sunucu tarafından
        bilgisayar, tablet ve/veya akıllı telefonlara gönderilen bir dizi
        karakter içeren basit, genellikle harf ve rakamlardan oluşan küçük bir
        metin dosyası olup, çerezin içeriğine erişip bilgileri okuyabilmektedir.
        Her çerez, web tarayıcısına özeldir. Ziyaretçinin minimum hafıza alanı
        kullanarak ve bilgisayar, tablet ve/veya akıllı telefonlara tehlikeye
        sokmadan, mevcut oturum sırasında sabit diske çerezleri kaydedilmesini
        sağlar. Çerezler IP adresiniz, oturum bilgileriniz, eriştiğiniz sayfalar
        ve benzer verileri saklamaktadır. Çerezler sayesinde web sitesi
        tercihleri hatırlandığı gibi, oturumun açık kalması sağlanabilir veya
        ilgilendiğiniz içerikler sunulmaktadır. Tarayıcıların büyük kısmı
        standart olarak çerezleri kabul eder ve bunlardan bağımsız olarak,
        geçici veya depolanmış çerezlerin güvenlik ayarlarına izin verir veya
        engellemektedir.
      </p>
      <h1 className="text-xl font bold mb-4 mt-4">Çerez Kategorileri</h1>
      <p>
        Web Sitesinin doğru şekilde çalışmasını sağlayan ve site özelliklerini
        kullanmanıza imkan tanıyan teknik bir çerezdir. Ziyaretçilerin bir
        sayfanın güvenli alanlarına erişim gibi sitenin özel alanlarında
        dolaşmasına ve kullanabilmesi için gereklidir. Kesinlikle gerekli
        çerezler olmadan web sitesinin bazı özelliklerinin kullanılamaması
        sonucu doğabilir. Operasyonel olarak hizmet, uygulama, araçlar için
        gereklidir. Kesin gerekli / zorunlu çerezler için ziyaretçi onayı
        gerekmemektedir.
      </p>
      <h1 className="text-xl font bold mb-4 mt-4">Performans Çerezleri</h1>
      <p>
        Web sitesini tekrar ziyaret eden ziyaretçilerin, dil/bölge vb.
        seçimlerinin hatırlanmasını sağlayarak daha kişiselleştirilmiş deneyim
        sunmayı sağlamaktadır.
      </p>
      <h1 className="text-xl font bold mb-4 mt-4">İşlevsellik Çerezleri</h1>
      <p>
        Web sitesini ziyaret eden ziyaretçi sayısını ve siteyi nasıl
        kullandıkları hakkında bilgi toplayan çerezlerdir. Web sitesi raporu
        derlemek amacıyla kullanılır. Web sitesini, ziyaretçilerin nasıl
        kullandığını anlamak, sitenin performansını ölçümlemek, siteyi optimize
        edip, geliştirmek, ilgi çekici ve amacına uygunluğundan emin olmak
        amacıyla kullanılmaktadır. Performans çerezlerinin çıktıları;
        görüntülenen sayfaları yönlendirilen sayfalar, çıkış yapılan sayfayı,
        ziyaretlerin zaman dilimi, fare (mouse) hareketleri, ekran sayfa
        kaydırma etkinliği, ziyaretçilerin web sayfalarında aldıkları hata
        mesajlarını alıp almadıkları, site içinde aranan arama terimleri
        göstermektedir. Bu çerezler, bir ziyaretçiyi tanımlamaz, anonim olarak
        bilgi toplama işlemi gerçekleştirmektedir.
      </p>
      <h1 className="text-xl font bold mb-4 mt-4">
        Reklam, Pazarlama ve Hedeflemeye Dair Çerezler
      </h1>
      <p>
        Web sitesi ziyaretçilerinin ilgi alanlarına uygun reklam kampanyası
        etkinliği yaratmak ve reklam sayısını ölçümlemek amacıyla birinci taraf
        çerezleri, üçüncü taraf çerezler ve web işaretçileri* kullanılmaktadır.
        Bu çerezler belirli web sayfa ziyaretlerinde etkileşim kurduğu reklam
        kampanyası etkinliğini ölçümlemek ve potansiyel reklam verenlerle
        iletişime imkan tanımak içinde kullanılmaktadır. Herhangi bir reklama
        olan ilgi dışında sunulan reklam ve içeriğinde faydalılığını anlamak
        için teknolojinin kullanılmasını da içermektedir. Bu çerezlerin tarayıcı
        ayarları değiştirilerek engellenmesi mümkündür.
      </p>
      <h1 className="text-xl font bold mb-4 mt-4">
        Web İşaretçileri (Web Beacons)
      </h1>
      <p>
        Ziyaretçilerin davranışlarını tespit etmek amacıyla çerezler ile
        birlikte çalışan ufak kısa grafik imgeleridir. Aynı zamanda “pixel
        etiketleri” ve/veya “clear GIFler” olarakta bilinmektedir.
      </p>
      <h1 className="text-xl font bold mb-4 mt-4">Çerez Türleri</h1>
      <p>
        Oturum Çerezleri <br></br>
        <p>
          Oturum çerezleri, tarayıcı oturumu sonlandığında sona eren geçici
          çerezler olup, oturumun sonlandırılmasıyla silinir. Web sitesinin
          düzgün çalışmasını sağlamak amacında çerezlerdir.
        </p>
      </p>
    </div>
  );
};

export default CerezPolitikasiPage;
