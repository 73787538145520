import React, { useRef } from "react";
import MapContainer from "./MapCaontainer";
import axios from "axios";
import { Helmet } from "react-helmet";
const QuotePage = () => {
  const formRef = useRef(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    // FormData objesi oluştur
    let formData = new FormData(event.target);
    let email;
    let companyname;
    let name;
    let phone;
    let message;
    // Form verilerini loglayın
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
      if (key === "name") {
        name = value;
      }
      if (key === "Phone") {
        phone = value;
      }
      if (key === "email") {
        email = value;
      }
      if (key === "company") {
        companyname = value;
      }
      if (key === "message") {
        message = value;
      }
    }

    try {
      const response = await axios.post(
        "https://webapp.ahdezemin.com/api/send-email/",
        {
          email,
          companyname,
          name,
          phone,
          message,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data); // İstekten dönen veriyi loglayın
    } catch (error) {
      console.error(error); // Hata durumunda hata mesajını loglayın
    } finally {
      alert("Mesajınız gönderildi.");
      formRef.current.reset();
    }
  };

  return (
    <div className="flex flex-col items-center max-sm:pt-4 pt-16 justify-start min-h-screen py-2 px-24 max-sm:px-6 bg-gray-200">
      <Helmet>
        <title>Ahde Zemin Sports - Teklif Al</title>
        <link
          rel="/"
          href="https://venerable-phoenix-f6f2be.netlify.app/quote"
        />
      </Helmet>
      <h1 className="text-6xl font-bold mb-16 max-sm:mb-4">İletişim </h1>
      <MapContainer />

      <form ref={formRef} onSubmit={handleSubmit}>
        <section className="text-gray-700 body-font relative">
          <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-col text-center w-full mb-12">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                İletişim
              </h1>
              <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                0532 166 62 78
              </p>
            </div>
            <div className="lg:w-1/2 md:w-2/3 mx-auto">
              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="name"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="w-full bg-gray-100 rounded border border-gray-300 focus:border-red-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="email"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="w-full bg-gray-100 rounded border border-gray-300 focus:border-red-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="Phone"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      id="Phone"
                      name="Phone"
                      className="w-full bg-gray-100 rounded border border-gray-300 focus:border-red-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="email"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Company
                    </label>
                    <input
                      type="company"
                      id="company"
                      name="company"
                      className="w-full bg-gray-100 rounded border border-gray-300 focus:border-red-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>
                </div>
                <div className="p-2 w-full">
                  <div className="relative">
                    <label
                      htmlFor="message"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      className="w-full bg-gray-100 rounded border border-gray-300 focus:border-red-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    ></textarea>
                  </div>
                </div>
                <div className="p-2 w-full">
                  <button className="flex mx-auto text-white bg-red-500 border-0 py-2 px-8 focus:outline-none hover:bg-red-600 rounded text-lg">
                    Send
                  </button>
                </div>
                <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                  <span className="text-red-500">
                    ahdezeminsportsinsaat@gmail.com
                  </span>
                  <p className="leading-normal my-5">
                    Hamidiye, Güldeste Sk. No : 10/A, 34758
                    <br />
                    Çekmeköy/İstanbul
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
};

export default QuotePage;
