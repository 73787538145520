import React from "react";
import mainLogo from "./assets/logo.png";
const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="pt-12 lg:pt-16">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div className="mb-16 grid grid-cols-2 gap-12 md:grid-cols-4 lg:grid-cols-6 lg:gap-8">
            <div className="col-span-full lg:col-span-2">
              <div className="mb-4 lg:-mt-2">
                <a
                  href="/"
                  className="inline-flex items-center gap-2 text-xl font-bold text-black md:text-2xl"
                  aria-label="logo"
                >
                  <img
                    src={mainLogo}
                    width="100px"
                    height="100px"
                    alt=""
                    className="h-10"
                  ></img>
                </a>
              </div>

              <p className="mb-6 text-gray-500 sm:pr-8">Ahde Zemin Sports</p>
              <div className="flex gap-4 mb-4">
                <a
                  href="/"
                  target="_blank"
                  className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600"
                >
                  <svg
                    className="h-5 w-5"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </a>

                <a
                  href="/"
                  target="_blank"
                  className="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600"
                >
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="100"
                    viewBox="0 0 24 24"
                  >
                    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.014467 17.065322 19.313017 13.21875 19.898438 L 13.21875 14.384766 L 15.546875 14.384766 L 15.912109 12.019531 L 13.21875 12.019531 L 13.21875 10.726562 C 13.21875 9.7435625 13.538984 8.8710938 14.458984 8.8710938 L 15.935547 8.8710938 L 15.935547 6.8066406 C 15.675547 6.7716406 15.126844 6.6953125 14.089844 6.6953125 C 11.923844 6.6953125 10.654297 7.8393125 10.654297 10.445312 L 10.654297 12.019531 L 8.4277344 12.019531 L 8.4277344 14.384766 L 10.654297 14.384766 L 10.654297 19.878906 C 6.8702905 19.240845 4 15.970237 4 12 C 4 7.5698774 7.5698774 4 12 4 z"></path>
                  </svg>
                </a>
              </div>
              <a href="tel:+905321666278" className="mr-5">
                <span className="select-all text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                  0532 166 62 78
                </span>
              </a>
              <a
                href="https://www.google.com/maps/place/Hamidiye,+Ahde+Zemin+Sports,+Güldeste+Sk.+No+:+10%2FA,+34758+Çekmeköy%2Fİstanbul/@41.0247714,29.1877298,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x14cacf8cc8eb80e3:0x8b3076d85652ff05!2m2!1d29.1903227!2d41.0247656!3e0"
                className=""
              >
                <span className="select-all text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                  Hamidiye, Güldeste Sk. No : 10/A, 34758 Çekmeköy/İstanbul
                </span>
              </a>
            </div>

            <div>
              <div className="mb-4 font-bold uppercase tracking-widest text-gray-800">
                Hizmetlerimiz
              </div>

              <nav className="flex flex-col gap-4">
                <div>
                  <a
                    href="/service-detail?id=basketball"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Basketbol Sahası Yapımı
                  </a>
                </div>

                <div>
                  <a
                    href="/service-detail?id=tenis"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Tenis Sahası Yapımı
                  </a>
                </div>

                <div>
                  <a
                    href="/service-detail?id=voleybol"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Voleybol Sahası Yapımı
                  </a>
                </div>
                <div>
                  <a
                    href="/service-detail?id=halisaha"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Halı Saha Yapımı
                  </a>
                </div>


                <div>
                  <a
                    href="/service-detail?id=atletizm"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Atletizm Pisti Yapımı
                  </a>
                </div>
              </nav>
            </div>

            <div>
              <div className="mb-4 font-bold uppercase tracking-widest text-gray-800">
                Spor Ekipmanları
              </div>

              <nav className="flex flex-col gap-4">
                <div>
                  <a
                    href="/service-detail?id=basketbolPotasi"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Basketbol Potası
                  </a>
                </div>

                <div>
                  <a
                    href="/service-detail?id=futbolKalesi"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Futbol Kalesi
                  </a>
                </div>

                <div>
                  <a
                    href="/service-detail?id=tenisEkipmanlari"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Tenis Ekipmanları
                  </a>
                </div>

                <div>
                  <a
                    href="/service-detail?id=voleybolDiregi"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Voleybol Direği
                  </a>
                </div>
                <div>
                  <a
                    href="/service-detail?id=yedekKulubesi"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Yedek Kulübesi
                  </a>
                </div>
              </nav>
            </div>
            <div>
              <div className="mb-4 font-bold uppercase tracking-widest text-gray-800">
                Zemin Kaplama Sistemleri
              </div>

              <nav className="flex flex-col gap-4">
                <div>
                  <a
                    href="/service-detail?id=akrilikZemin"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Akrilik Zemin Kaplama
                  </a>
                </div>

                <div>
                  <a
                    href="/service-detail?id=epoksiZemin"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Epoksi Zemin Kaplama
                  </a>
                </div>

                <div>
                  <a
                    href="/service-detail?id=asfaltZemin"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Asfalt Zemin Kaplama
                  </a>
                </div>

                <div>
                  <a
                    href="/service-detail?id=sentetikCimZemin"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Sentetik Çim Kaplama
                  </a>
                </div>
                <div>
                  <a
                    href="/service-detail?id=kaucukZemin"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Kauçuk Zemin Kaplama   
                  </a>
                </div>

              </nav>
            </div>
            <div>
              <div className="mb-4 font-bold uppercase tracking-widest text-gray-800">
                Gizlilik ve Destek
              </div>

              <nav className="flex flex-col gap-4">
                <div>
                  <a
                    href="/"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Aydınlantma Metni
                  </a>
                </div>

                <div>
                  <a
                    href="/"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Sıkça Sorulanlar
                  </a>
                </div>

                <div>
                  <a
                    href="/cerez-politikasi"
                    className="text-gray-500 transition duration-100 hover:text-red-500 active:text-red-600"
                  >
                    Çerez Politikası
                  </a>
                </div>
              </nav>
            </div>
          </div>

          <div className="border-t py-8 text-center text-sm text-gray-400">
            Copyright © 2024 - Tüm Hakları Ahde Zemin Sport'a Saklıdır.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
