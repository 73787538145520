import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import ProductsPage from "./ProductsPage";
import ContactPage from "./ContactPage";
import InstitutionalPage from "./InstitutionalPage";
import ProjectsPage from "./ProjectsPage";
import ReferencesPage from "./ReferencesPage";
import QuotePage from "./QuotePage";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ServiceDetailPage from "./ServiceDetailPage";
import ProductDetailPage from "./ProductDetailPage";
import FloorCoatingDetailPage from "./FloorCoatingDetailPage";
import CerezPolitikasiPage from "./CerezPolitikasi";
// import Breadcrumbs from './Breadcrumbs'; // Breadcrumbs komponentini içe aktarın

function App() {
  // const breadcrumbs = [
  //   { label: 'Ana Sayfa', link: '/' },
  //   { label: 'Ürünler', link: '/products' },
  //   { label: 'Kurumsal', link: '/institutional' },
  //   { label: 'Contact', link: '/contact' },
  //   { label: 'Projects', link: '/projects' },
  //   { label: 'References', link: '/references' },
  //   { label: 'Ürün Detayı', link: '/product-detail' },
  // ];

  return (
    <Router>
      <Navbar /> {/* Navbar'ı buraya ekleyin */}
      {/* <Breadcrumbs breadcrumbs={breadcrumbs} /> */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* Home */}
        <Route path="/products" element={<ProductsPage />} />
        {/* Ürünler */}
        <Route path="/institutional" element={<InstitutionalPage />} />
        {/* Kurumsal */}
        <Route path="/contact" element={<ContactPage />} />
        {/* Contact */}
        <Route path="/projects" element={<ProjectsPage />} />
        {/* Ürünler */}
        <Route path="/references" element={<ReferencesPage />} />
        {/* Referanslar */}
        <Route path="/quote" element={<QuotePage />} />
        {/* Get a Quote */}
        <Route path="/service-detail" element={<ServiceDetailPage />} />
        <Route path="/product-detail" element={<ProductDetailPage />} />
        <Route
          path="/floorCoating-detail"
          element={<FloorCoatingDetailPage />}
        />
        <Route path="/cerez-politikasi" element={<CerezPolitikasiPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

// Home - Kurumsal - Ürünler - Projeler - Referanslar - Contact - Teklif Al
