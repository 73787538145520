import React from "react";
// import { Helmet } from "react-helmet";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ProjectsPage = () => {

  const products = [
    {
      place: "KATAR",
      description: " Kurşunlu Zemin Kaplama - Akrilik Zemin Kaplama",
      images: [
        "./assets/katar-project/katar-1.jpg",
        "./assets/katar-project/katar-2.jpg",
        "./assets/katar-project/katar-3.jpg"

      ],
      id: "basketbolPotasi",
    },
    {
      place: "AFGANİSTAN",
      description: " Akrilik Zemin Kaplama - Basketbol ve Tenis Sahası Yapımı",
      images: [
        "./assets/afganistan-projects/afganistan-1.jpg",
        "./assets/afganistan-projects/afganistan-2.jpg",
        "./assets/afganistan-projects/afganistan-3.jpg"
      ],
      id: "futbolKalesi",
    },
    {
      place: "UMMAN",
      description: " Akrilik Zemin Kaplama - Basketbol Sahası Yapımı",
      images: [
        "./assets/umman-projects/umman-1.jpg",
        "./assets/umman-projects/umman-2.jpg"
      ],
      id: "tenisEkipmanlari",
    },
    {
      place: "BODRUM",
      description: "Akrilik Zemin Kaplama - Tenis Sahası Yapımı",
      images: [
        "./assets/bodrum-projects/bodrum-1.png",
        "./assets/bodrum-projects/bodrum-2.png",
        "./assets/bodrum-projects/bodrum-3.png",
        "./assets/bodrum-projects/bodrum-4.png",
        "./assets/bodrum-projects/bodrum-5.png"

      ],
      id: "voleybolDiregi",
    },
    {
      place: "ŞIRNAK CUDİ CUP ULUSLARASI TENİS TURNUVASI",
      description: "Akrilik Zemin Kaplama - Tenis Sahası Yapımı",
      images: [
        "./assets/cudi-cup-project/cudi-1.jpeg",
        "./assets/cudi-cup-project/cudi-2.png",
        "./assets/cudi-cup-project/cudi-3.jpeg",
        "./assets/cudi-cup-project/cudi-4.jpeg",
        "./assets/cudi-cup-project/cudi-5.jpeg",
        "./assets/cudi-cup-project/cudi-6.jpeg",
      ],
      id: "skorboard",
    },
  ];



  return (
    <div className="wrap px-10 py-5">
      {products.map((product, index) => (
        <div key={index} className="mb-10">

          <article
            className="relative isolate flex flex-col justify-center items-center overflow-hidden rounded-2xl p-6 mx-auto group"
          >

            <h3 className="text-xl font-bold text-gray-900">
              {product.place}
            </h3>

            <Carousel
              autoPlay={true}
              showArrows={true}
              showThumbs={false}
              dynamicHeight={false}
              className="rounded-2xl w-full max-w-2xl mx-auto"
            >
              {product.images.map((image, i) => (
                <div
                  key={i}
                  style={{ position: "relative", width: "100%", height: "auto" }}
                >
                  <img
                    alt={`img-${i + 1}`}
                    src={image}
                    style={{ width: "auto", height: "auto", objectFit: "contain" }}
                  />
                </div>
              ))}
            </Carousel>
            <div className="mt-3 text-center w-full">
              <h3 className="text-xl text-gray-900">
                {product.description}
              </h3>
            </div>
          </article>
        </div>
      ))}
    </div>
  );
};

export default ProjectsPage;