import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Carousel from 'react-responsive-carousel/lib/js/components/Carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const FloorCoatingDetailPage = () => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const id = searchParams.get('id');
    const [data, setData] = useState(null);
    const [openFAQs, setOpenFAQs] = useState([]);

    useEffect(() => {
        if (id) {
          fetch(`/json/${id}.json`)
            .then((response) => response.json())
            .then((data) => {
              console.log(`Fetched data:`, data);
              setData(data);
              const carouselImages = document.querySelectorAll(
                ".carousel .slide img"
              );
    
              carouselImages.forEach((img) => {
                const aspectRatio = 16 / 9; // Belirlenen aspect ratio
                const containerWidth = img.parentElement.offsetWidth;
                const newHeight = containerWidth / aspectRatio;
                img.style.height = `${newHeight}px`;
              });
            })
            .catch((error) => console.error("Error fetching data:", error));
        }
      }, [id]);

    const toggleFAQ = (index) => {
        setOpenFAQs(openFAQs.includes(index)
            ? openFAQs.filter(faqIndex => faqIndex !== index)
            : [...openFAQs, index]);
    };

    return (
        <div className="container mx-auto py-8 px-4">
            {data && (
                <div className="m-8">
                    <div className="text-center py-4">
                        <h1 className="text-4xl font-bold mb-4">{data.data.title}</h1>
                    </div>
                    <div className="w-full max-w-4xl mx-auto mb-8">
            <Carousel
              autoPlay={true}
              showArrows={true}
              showThumbs={false}
              dynamicHeight={false}
            >
              {data.data.carrouselData.map((item, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "auto",
                  }}
                >
                  <img
                    alt={`img-${index + 1}`}
                    src={item.url}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  {/* <p
                    className="legend"
                    style={{ position: "absolute", bottom: 0 }}
                  >
                    {item.content}
                  </p> */}
                </div>
              ))}
            </Carousel>
          </div>
                    
                    <div className="mt-8">
                        {data.data.content.map((section, index) => (
                            <div key={index} className="mb-8">
                                <h2 className="text-2xl font-semibold mb-4">{section.sectionTitle}</h2>
                                {section.sectionTitle !== "Sıkça Sorulan Sorular" ? (
                                    <ul className="list-disc pl-6 space-y-2">
                                        {section.items.map((item, subIndex) => (
                                            <li key={subIndex}>{item}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <div className="space-y-4">
                                        {section.faqItems.map((item, subIndex) => (
                                            <div
                                                key={subIndex}
                                                className="p-4 border cursor-pointer hover:bg-gray-100 transition-colors duration-200"
                                                onClick={() => toggleFAQ(subIndex)}
                                            >
                                                <div>
                                                    <strong className="font-semibold">{item.faqQuestion}</strong>
                                                    {openFAQs.includes(subIndex) && (
                                                        <p className="mt-2">{item.faqAnswer}</p>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FloorCoatingDetailPage;
