import React, { useState, useEffect, useRef } from "react";
import HomePage from "./HomePage";
// import ProductsPage from "./ProductsPage";
import ContactPage from "./ContactPage";
import InstitutionalPage from "./InstitutionalPage";
import ProjectsPage from "./ProjectsPage";
import ReferencesPage from "./ReferencesPage";
// import QuotePage from './QuotePage';
import mainLogo from "./assets/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
const Navbar = () => {
  const location = useLocation();

  const isQuotePage = location.pathname === "/quote";
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null);
  const navigate = useNavigate();
  const routes = [
    { path: "/", name: "Ana Sayfa", component: HomePage },
    // { path: "/products", name: "Spor Ekipmanları", component: ProductsPage },
    { path: "/institutional", name: "Kurumsal", component: InstitutionalPage },
    { path: "/contact", name: "İletişim", component: ContactPage },
    { path: "/projects", name: "Projeler", component: ProjectsPage },
    { path: "/references", name: "Referanslar", component: ReferencesPage },
    // { path: "/quote", name: "Get a Quote", component: QuotePage }
  ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  // Define handleClickOutside outside of useEffect to avoid recreating it on every render

  const teklifAl = () => {
    // Tıklama durumunda /quote sayfasına yönlendiriyoruz
    navigate("/quote");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".navbar-backdrop")) {
        // Assuming toggleMenu is correctly implemented to toggle isOpen
        setIsOpen(!isOpen);
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]); // Simplified dependencies

  return (
    <div ref={navRef}>
      <nav className="relative px-4 py-4 flex justify-between items-center bg-white">
        <a
          className="mr-auto text-3xl font-bold leading-none flex items-center"
          href="/"
        >
          <img
            alt="logo"
            src={mainLogo}
            width="40px"
            height="40px"
            className="h-10 w-auto mr-2"
          ></img>
          <h5 className="text-sm text-red-600">Ahde Zemin Sports</h5>
        </a>
        <div className="lg:hidden">
          <button
            onClick={toggleMenu}
            className="navbar-burger flex items-center text-red-600 p-3"
          >
            <svg
              className="block h-4 w-4 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Mobile menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>
        <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">
          {routes.map((route, index) => (
            <li key={index}>
              <Link to={route.path}>
                <button
                  className={`text-sm ${
                    route.path === location.pathname
                      ? "text-red-600 font-bold"
                      : "text-gray-800 hover:text-gray-500"
                  } rounded hover:text-red-600 font-bold hover:font-bolder`}
                >
                  {route.name}
                </button>
              </Link>
            </li>
          ))}
        </ul>
        <button
          onClick={teklifAl}
          className={`py-2 px-6 bg-red-500 max-md:hidden hover:bg-red-600 text-sm text-white font-bold rounded-xl transition duration-200 ${
            isQuotePage ? "hidden" : "lg:inline-block"
          }`}
          href="#"
        >
          Teklif Al
        </button>
      </nav>
      {isOpen && (
        <div className="navbar-menu relative z-50">
          <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
          <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
            <div className="flex items-center mb-8">
              <button
                className="mr-auto text-3xl font-bold leading-none flex items-center"
                href="/"
              >
                <img
                  alt="logo"
                  width="40px"
                  height="40px"
                  src={mainLogo}
                  className="h-10 mr-2"
                ></img>
                <h5 className="text-sm text-red-600">Ahde Zemin Sports</h5>
              </button>
              <button className="navbar-close" onClick={() => setIsOpen(false)}>
                <svg
                  className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              <ul>
                {routes.map((route, index) => (
                  <li onClick={toggleMenu} key={index} className="mb-1">
                    <Link to={route.path}>
                      <button className="block p-4 text-sm font-semibold text-gray-800 hover:bg-blue-50  rounded hover:text-red-600 hover:font-bold">
                        {route.name}
                      </button>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </div>
      )}
      <button
        onClick={teklifAl}
        className={`md:hidden animate-bounce z-[1000] fixed bottom-4 right-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ${
          isQuotePage ? "hidden" : ""
        }`}
        href="#"
      >
        Teklif Al
      </button>
    </div>
  );
};

export default Navbar;
