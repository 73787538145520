import React from "react";
import MapContainer from "./MapCaontainer";
import { Helmet } from "react-helmet";
const ContactPage = () => {
  return (
    <div className="flex flex-col items-center justify-start min-h-screen py-2 px-24">
      <Helmet>
        <title>Ahde Zemin Sports - Iletişim</title>
        <link
          rel="/"
          href="https://venerable-phoenix-f6f2be.netlify.app/contact"
        />
      </Helmet>
      <h1 className="text-3xl font-bold my-16 max-sm:my-4">İletişim</h1>
      <MapContainer />
      <div className="flex mt-4">
        <div className="col-span-1">
          <h3>Adres</h3>
          <p className="pr-4">
            Hamidiye, Güldeste Sk. No : 10/A, 34758 Çekmeköy/İstanbul
          </p>
          <p>
            <a href="tel:+905321666278" className="mr-5">
              <span className="select-al transition duration-100 hover:text-gray-500 active:text-gray-600">
                0532 166 62 78
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
