import { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";
// import { useViewport } from "./hooks/useViewport";
register();

const Swiper = ({ data, dataUrl }) => {
  // const [windowDimensions] = useViewport(); // useViewport hook'unun doğru şekilde çalıştığından emin olun
  // const { width } = windowDimensions;
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiperContainer = swiperRef.current;
    const params = {
      navigation: true,
      pagination: true,
      zoom: true,
      // keyboard: true,
      // preventClicks: true,
      spaceBetween: 30,
      breakpoints: {
        1378: {
          slidesPerView: 5,
          slidesPerGroup: 5,
        },
        998: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
        625: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        0: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
      injectStyles: [
        `
          .swiper-button-next,
          .swiper-button-prev {
            padding: 8px 16px;
            color: red;
          }
          .swiper-pagination-bullet{
            border-radius: 10px;
            width: 10px;
            height: 5px;
            background-color: red;
          }
          .swiper.swiper-horizontal{
            padding-top: 25px;
          }
          .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal{
          text-align: right;
          bottom: auto;
          top: 0;
          }
      `,
      ],
    };

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, []);

  return (
    <swiper-container ref={swiperRef} init="false">
      {data.map((item, index) => (
        <swiper-slide key={index}>
          <article
            key={index}
            className="relative h-80 isolate flex flex-col justify-end overflow-hidden rounded-2xl px-20 pb-8 pt-40  mx-auto hover:scale-110"
            onClick={() => {
              
              
              const url = `${dataUrl}?id=${encodeURIComponent(item.id)}`;
              window.location.href = url;
            }}
          >
            <img
              src={item.image}
              width="240px"
              height="320px"
              alt="University of Southern California"
              className="absolute inset-0 h-full w-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40"></div>
            <h3 className="z-10 mt-3 text-3xl font-bold text-white opacity-50 transition-opacity duration-500 ease-in-out group-hover:opacity-100">
              {item.title}
            </h3>
            <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 text-gray-300 group-hover:text-gray-400"></div>
          </article>
        </swiper-slide>
      ))}
      {/* <swiper-slide class="blue-slide">Slide 1</swiper-slide 111>
      <swiper-slide class="yellow-slide">Slide 2</swiper-slide>
      <swiper-slide class="green-slide">Slide 3</swiper-slide> */}
    </swiper-container>
  );
};

export default Swiper;
