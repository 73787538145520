import React from "react";
import { Helmet } from "react-helmet";

const InstitutionalPage = () => {
  return (
    <div className="flex flex-col items-center min-h-screen py-8 px-6 lg:px-20 bg-gray-50">
      <Helmet>
        <title>Ahde Zemin Sports - Hakkımızda</title>
        <link
          rel="/"
          href="https://venerable-phoenix-f6f2be.netlify.app/institutional"
        />
      </Helmet>
      <h1 className="text-4xl font-extrabold text-gray-800 mb-8">
        Hakkımızda
      </h1>
      <p className="text-lg text-gray-700 max-w-4xl leading-relaxed mb-8">
        Sektöründe 30 yıla yakın deneyimi olan uzman ekiplerimizle AHDE ZEMİN SPORTS (ZEMİN SPORTS 2009) çatısı altında 2021 yılında isim değişikliği ile yenilenen firmamız, aynı anda bir çok projeyi, zengin ürün yelpazesi ve üstün kalite ile yürütebilmektedir. Geniş kadro ve hizmet ağımızla her bütçeye uygun seçenekler sunuyor, çözüm ortaklarımızın markalarına hacim ve değer katıyoruz. Daimi inovasyon vizyonumuz ile yeni teknolojileri inceliyor, üretiyor ve projelerimize dahil ediyoruz. Projelerimizi, her aşamasında özenle takip etmemiz, en iyi şekilde ve zamanında teslim ediyor olmamız, bugünkü güven ve saygınlığa ulaşmamızın daimi sebebidir.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        Vizyonumuz
      </h2>
      <p className="text-lg text-gray-700 max-w-4xl leading-relaxed mb-8">
        AHDE ZEMİN SPORTS olarak vizyonumuz, spor yapıları ve zemin kaplama sektöründe yenilikçi ve kaliteli çözümler sunarak, müşterilerimizin beklentilerini aşmak ve sektörde lider bir konuma ulaşmaktır. Geniş ürün yelpazemiz ve deneyimli ekibimizle, her bütçeye uygun çözümler sunarken, daimi inovasyon anlayışımızla yeni teknolojileri projelerimize entegre ediyoruz. Müşteri memnuniyeti ve güvenilirliği ön planda tutarak, spor yapı projelerinin her aşamasında titizlikle çalışıyor ve zamanında teslim ediyoruz. AHDE ZEMİN SPORTS olarak, sürekli gelişim ve mükemmeliyet hedefiyle sektörümüzde saygın bir marka olmayı amaçlıyoruz.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        Misyonumuz
      </h2>
      <p className="text-lg text-gray-700 max-w-4xl leading-relaxed mb-8">
        AHDE ZEMİN SPORTS olarak misyonumuz, spor yapıları ve zemin kaplama çözümlerinde üstün kalite ve yenilikçi yaklaşımlarla müşterilerimize en iyi hizmeti sunmaktır. 30 yıla yakın deneyimimizle, geniş kadro ve hizmet ağımız sayesinde her türlü spor yapısı projesini başarıyla tamamlıyor, çözüm ortaklarımızın markalarına değer katıyoruz. Her projeyi özenle takip ederek, en yüksek standartlarda ve zamanında teslim etmeyi garanti ediyoruz. Spor ekipmanları, zemin kaplama sistemleri ve tel çit sistemlerinden, çelik konstrüksiyonlara kadar geniş bir ürün ve hizmet yelpazesi sunarak, spor alanlarının kalitesini artırmayı hedefliyoruz. Müşteri memnuniyeti, güven ve kaliteli hizmet anlayışımızla sektördeki güvenilirliğimizi sürdürmeye devam ediyoruz.
      </p>
    </div>
  );
};

export default InstitutionalPage;
