import React from "react";
import { Helmet } from "react-helmet";

const ReferencesPage = () => {
  const references = [
    {
      title: "Irak – Akrilik Zemin Kaplama, Basketbol Sahası Yapımı",
      year: "2024",
    },
    {
      title: "Şırnak Cudi Cup Turnuvası - Akrilik Zemin Kaplama, Tenis Sahası Yapımı, Tel Çit Örgü",
      year: "2023-2024",
    },
    {
      title: "Trabzon – Epoksi Zemin Kaplama",
      year: "2023",
    },
    {
      title: "Eskişehir KYK Yurdu – Akrilik Zemin Kaplama, Tenis Sahası Yapımı",
      year: "2023",
    },
    {
      title: "Sakarya/Hendek - Akrilik Zemin Kaplama, Basketbol Sahası, Tribün Koltukları",
      year: "2022",
    },
    {
      title: "Afganistan – Akrilik Zemin Kaplama, Basketbol Sahası Yapımı, Voleybol Sahası Yapımı",
      year: "2022",
    },
    {
      title: "Bodrum – Akrilik Zemin Kaplama, Basketbol Sahası Yapımı, Tel Çit Örgü",
      year: "2022",
    },
    {
      title: "Kayseri – Epoksi Zemin Kaplama",
      year: "2022",
    },
    {
      title: "Bodrum Seba Taş Evler- Akrilik Zemin, Tenis Sahası Yapımı, Tel Çit Yapımı",
      year: "2022",
    },
    {
      title: "Şile - Akrilik Zemin, Basketbol Sahası Yapımı, Tel Çit Örgü",
      year: "2022",
    },
    {
      title: "Katar - Kurşunlu Zemin Kaplama, Akrilik Zemin Kaplama",
      year: "2021",
    },
    {
      title: "Bodrum Yalıkavak - Akrilik Zemin Kaplama Tenis Sahası Yapımı",
      year: "2019",
    },
    {
      title: "Umman - Akrilik Zemin, Tenis Sahası Yapımı, Tel Çit Yapımı",
      year: "2018",
    },
    {
      title: "Bursa- Akrilik Zemin Kaplama, Basketbol Sahası Yapımı",
      year: "2018",
    },
    {
      title: "Bulgaristan – Akrilik Zemin Kaplama, Basketbol Sahası Yapımı, Voleybol Sahası Yapımı, Atletizm Pisti Yapımı",
      year: "2017",
    },
    {
      title: "İznik Belediyesi – Akrilik Zemin Kaplama, Basketbol Sahası Yapımı, Tel Çit Örgü",
      year: "2017",
    },
    {
      title: "ŞanlıUrfa – Sentetik Çim Kaplama, Halı Saha Yapımı, Tel Çit Örgü",
      year: "2016",
    },
    {
      title: "Kıbrıs - Akrilik Zemin Kaplama, Tenis Kortu",
      year: "2016",
    },
    {
      title: "Malatya Toki Evleri – Akrilik Zemin, Basketbol Saha Yapımı",
      year: "2014",
    },
    {
      title: "Mısır - Akrilik Zemin Kaplama, Sentetik Çim Zemin Kaplama, Atletizm Pisti Yapımı, Halı Saha Yapımı",
      year: "2013",
    },
    {
      title: "Naris Sitesi – Akrilik Zemin Kaplama, Basketbol Sahası Yapımı, Tenis Sahası Yapımı, Tel Çit Yapımı",
      year: "2013",
    },
    {
      title: "Tekirdağ/Çorlu - Akrilik Zemin, Tenis Sahası Yapımı, Tel Çit Yapımı",
      year: "2012",
    }
  ];
  

  return (
    <div className="flex flex-col items-center min-h-screen py-2 px-6 md:px-24">
      <Helmet>
        <title>Ahde Zemin Sports - Referanslarımız</title>
        <link
          rel="canonical"
          href="https://venerable-phoenix-f6f2be.netlify.app/references"
        />
      </Helmet>
      <h3 className="text-center text-3xl font-semibold text-gray-700 py-8">
        Referanslarımız
      </h3>
      <div className="flex flex-wrap justify-center items-center gap-4">
        <img
          src="./assets/reference/referans-1.jpg"
          alt="Logo 1"
          className="w-24 h-24"
        />
        <img
          src="./assets/reference/referans-2.png"
          alt="Logo 2"
          className="w-24 h-24"
        />
        <img
          src="./assets/reference/referans-3.png"
          alt="Logo 3"
          className="w-24 h-24"
        />
        <img
          src="./assets/reference/referans-4.png"
          alt="Logo 4"
          className="w-24 h-24"
        />
        <img
          src="./assets/reference/referans-5.png"
          alt="Logo 5"
          className="w-24 h-24"
        />
        <img
          src="./assets/reference/referans-6.jpg"
          alt="Logo 6"
          className="w-24 h-24"
        />

  
      </div>
      <div className="flex flex-col items-start mt-16 space-y-6 border-l-2 border-dashed pl-6">
        
        {references.map((reference, index) => (
          <div key={index}
          className={`relative w-full p-4 ${
            index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
          } rounded-lg shadow-md`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="absolute -bottom m-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-red-500"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clipRule="evenodd"
              />
            </svg>
            <div className="ml-6">
              <h4 className="font-bold text-black-500">{reference.title}</h4>
              <span className="mt-1 block text-sm font-semibold text-red-500">
                {reference.year}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReferencesPage;
