import React from "react";
import Iframe from "react-iframe";

const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const MapContainer = () => {
  const handleClick = () => {
    window.open(
      "https://www.google.com/maps/place/Hamidiye,+Ahde+Zemin+Sports,+Güldeste+Sk.+No+:+10%2FA,+34758+Çekmeköy%2Fİstanbul/@41.0247714,29.1877298,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x14cacf8cc8eb80e3:0x8b3076d85652ff05!2m2!1d29.1903227!2d41.0247656!3e0",
      "_blank"
    );
  };
  return (
    // <APIProvider apiKey={googleMapApiKey}>
    //   <Map
    //     style={{ width: "100%", height: "400px" }}
    //     zoom={18}
    //     center={{ lat: 41.0248, lng: 29.19026 }}
    //   ></Map>

    // </APIProvider>
    <div className="flex flex-col items-center w-full">
      <Iframe
        url={`https://www.google.com/maps/embed/v1/place?q=Ahde+Zemin+Sports/@41.0247714,29.1877298,17z/data=!3m1!4b1!4m16!1m9!4m8!1m0!1m6!1m2!1s0x14cacf8cc8eb80e3:0x8b3076d85652ff05!2zSGFtaWRpeWUsIEfDvGxkZXN0ZSBTay4gTm8gOiAxMC9BLCAzNDc1OCDDh2VrbWVrw7Z5L8Swc3RhbmJ1bA!2m2!1d29.1903227!2d41.0247656!3m5!1s0x14cacf8cc8eb80e3:0x8b3076d85652ff05!8m2!3d41.0247674!4d29.1903101!16s%2Fg%2F11rqg_kclf&key=${googleMapApiKey}`}
        width="100%"
        height="400px"
        loading="lazy"
        className="myClassname"
        referrerpolicy="no-referrer-when-downgrade"
        display="initial"
        position="relative"
        allowFullScreen
      />
      <button
        onClick={handleClick}
        className="min-w-[200px] mt-4 bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-auto rounded self-center"
      >
        Adrese Git
      </button>
    </div>

    // <iframe
    //   width="600"
    //   height="450"
    //   style="border:0"
    //   loading="lazy"
    //   allowfullscreen
    //   referrerpolicy="no-referrer-when-downgrade"
    //   src="https://www.google.com/maps/embed/v1/place?key=API_KEY
    // &q=Space+Needle,Seattle+WA"
    // ></iframe>
  );
};

export default MapContainer;
