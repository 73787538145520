import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import { Pagination, Navigation, Scrollbar } from "swiper/modules";
// import { Swiper } from "./Swiper";
import Swiper from "./Swiper";
import './swiper.css';
// import required modules

const DisplayRowContainer = ({ title, data, url }) => {
  return (
    <div className="block  mx-auto max-sm:px-2 px-10">
      <Swiper data={data} dataUrl={url}/>
    </div>
  );
};

export default DisplayRowContainer;
